import React, { useState } from 'react';
import { Check, ExclamationCircle } from 'heroicons-react';
import { useHistory } from 'react-router-dom';

import Loader from '../../shared/Loader';
import api from '../../../utils/api';

const UserDetails = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [creatingUser, setCreatingUser] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const history = useHistory();

    const onSubmit = async (event) => {
        setCreatingUser(true);
        try {
            event.preventDefault();
            const response = await api.createUser({
                name,
                email,
                phoneNumber,
                roles: [],
            });
            history.push(`/users/type/unified/userId/${response.userId}`);
        } catch (error) {
            console.error(error);
            setErrorMessage(error.response?.data?.error || 'Something went wrong.');
        }
        setCreatingUser(false);
    };

    return (
        <section className="p-6 h-full">
            <h1 className="mb-4 text-blue-800 text-3xl font-thin">Create user</h1>
            <form onSubmit={onSubmit}>
                <label className="mb-4 block text-sm">
                    <span className="mb-1 block">Name (required)</span>
                    <input
                        className="rounded border border-gray-400 py-2 px-3 w-full md:w-1/2"
                        type="text"
                        value={name}
                        onChange={(event) => setName(event.target.value)}
                        placeholder="Enter name..."
                    />
                </label>
                <label className="mb-4 block text-sm">
                    <span className="mb-1 block">Email address (required)</span>
                    <input
                        className="rounded border border-gray-400 py-2 px-3 w-full md:w-1/2"
                        type="text"
                        value={email}
                        onChange={(event) => setEmail(event.target.value)}
                        placeholder="Enter email address..."
                    />
                </label>
                <label className="mb-4 block text-sm">
                    <span className="mb-1 block">Phone number</span>
                    <input
                        className="rounded border border-gray-400 py-2 px-3 w-full md:w-1/2"
                        type="text"
                        value={phoneNumber}
                        onChange={(event) => setPhoneNumber(event.target.value)}
                        placeholder="Enter phone number..."
                    />
                </label>
                <button
                    className="rounded py-2 px-4 mr-2 mb-2 bg-green-700 font-light text-white hover:bg-green-800 flex"
                    type="submit"
                >
                    {creatingUser ? (
                        <div className="-ml-1 mr-3">
                            <Loader />
                        </div>
                    ) : (
                        <Check className="mr-3" />
                    )}
                    Create
                </button>
            </form>
            {errorMessage && (
                <p className="text-sm text-red-700 mt-1 flex items-center">
                    <ExclamationCircle className="mr-1" />
                    {errorMessage}
                </p>
            )}
        </section>
    );
};

export default UserDetails;
