import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import Amplify from 'aws-amplify';

import config from './utils/config';

import './assets/css/tailwind.output.css';
import Home from './components/Home';

Amplify.configure(config);

const App = () => {
    return (
        <Router>
            <Home />
        </Router>
    );
};

export default App;
