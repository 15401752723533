import React, { useState, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { CheckCircle, Ban, ExclamationCircle, ArrowCircleLeft } from 'heroicons-react';

import Tag from '../../shared/Tag';
import Loader from '../../shared/Loader';
import { prettyDate, prettyDateTime } from '../../../utils/shared';
import api from '../../../utils/api';
import { prescreenStatusColors } from '../../../utils/colors';

const UserPrescreen = () => {
    const [userLoading, setUserLoading] = useState(false);
    const [userData, setUserData] = useState({});
    const [didError, setDidError] = useState(false);
    const [confirmApprove, setConfirmApprove] = useState(false);
    const [confirmReject, setConfirmReject] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [approved, setApproved] = useState(false);
    const [rejected, setRejected] = useState(false);
    const [rejectionReason, setRejectionReason] = useState('');
    const [confirmRejectValidationError, setConfirmRejectValidationError] = useState(false);

    const { onboardingId } = useParams();
    const history = useHistory();

    const getUserData = useCallback(async () => {
        setUserLoading(true);

        if (!onboardingId) return;

        try {
            const userResponse = await api.getUserOnboarding(onboardingId);
            setUserData(userResponse);
        } catch (err) {
            // If the user is not found, handle gracefully, otherwise display an error message
            if (err.response?.status !== 404) {
                console.error(err);
                setDidError(err.message);
            }
        }

        setUserLoading(false);
    }, [onboardingId]);

    useEffect(() => {
        getUserData();
    }, [getUserData]);

    const updatePrescreenStatus = async (prescreenResult) => {
        setConfirmRejectValidationError(false);

        try {
            const body = { prescreenResult };

            if (prescreenResult === 'REJECTED' && rejectionReason === '') {
                setConfirmRejectValidationError(true);
                return;
            }

            setIsSubmitting(true);

            if (prescreenResult === 'REJECTED') {
                body.rejectionReason = rejectionReason;
            }

            await api.updateUserOnboarding(onboardingId, body);

            if (prescreenResult === 'APPROVED') {
                setApproved(true);
                setConfirmApprove(false);
            } else {
                setRejected(true);
                setConfirmReject(false);
            }
        } catch (err) {
            console.error(err);
        }

        setIsSubmitting(false);
    };

    const {
        name,
        prescreenResult,
        hcpType,
        gmcRegistrationDate,
        professionalBodyType,
        professionalBodyId,
        primaryGPFunction,
        email,
        phoneNumber,
        rejectionReason: priorRejectionReason,
        rejectedAt,
        rejectedBy,
        campaign,
    } = userData;

    return (
        <section className="p-6 h-full">
            <button
                className="rounded py-1 px-2 mb-4 bg-teal-600 font-light text-white hover:bg-teal-700 flex text-sm items-center"
                onClick={history.goBack}
            >
                <ArrowCircleLeft className="mr-1" size={20} />
                Back to list
            </button>
            {userLoading ? (
                <div className="text-teal-600 flex justify-center my-5">
                    <Loader size={10} />
                </div>
            ) : didError ? (
                <p className="text-sm text-red-700 mb-1 flex items-center">
                    <ExclamationCircle className="mr-1" />
                    Error loading user details: {didError}
                </p>
            ) : (
                <>
                    <h1 className="mb-4 text-blue-800 text-3xl font-thin flex items-center">
                        {name}
                        <Tag color={prescreenStatusColors[prescreenResult]}>{prescreenResult}</Tag>
                    </h1>
                    <dl className="p-3 border-t border-gray-300 flex flex-col sm:flex-row sm:flex-wrap text-sm">
                        <dt className="sm:w-1/3 font-bold">Professional status</dt>
                        <dd className="sm:w-2/3 py-1">{hcpType}</dd>
                        {primaryGPFunction && (
                            <>
                                <dt className="sm:w-1/3 font-bold">Primary GP function</dt>
                                <dd className="sm:w-2/3 py-1">{primaryGPFunction}</dd>
                            </>
                        )}
                        {professionalBodyType && (
                            <>
                                <dt className="sm:w-1/3 font-bold">Professional body type</dt>
                                <dd className="sm:w-2/3 py-1">{professionalBodyType}</dd>
                            </>
                        )}
                        {professionalBodyId && (
                            <>
                                <dt className="sm:w-1/3 font-bold">Professional body ID</dt>
                                <dd className="sm:w-2/3 py-1">{professionalBodyId}</dd>
                            </>
                        )}
                        {gmcRegistrationDate && (
                            <>
                                <dt className="sm:w-1/3 font-bold">GMC registraion date</dt>
                                <dd className="sm:w-2/3 py-1">{prettyDate(gmcRegistrationDate)}</dd>
                            </>
                        )}
                        <dt className="sm:w-1/3 font-bold">Email address</dt>
                        <dd className="sm:w-2/3 py-1">
                            <a
                                className="underline text-teal-600 hover:text-teal-700"
                                href={`mailto:${email}`}
                            >
                                {email}
                            </a>
                        </dd>
                        <dt className="sm:w-1/3 font-bold">Phone number</dt>
                        <dd className="sm:w-2/3 py-1">{phoneNumber}</dd>
                        {campaign && (
                            <>
                                <dt className="sm:w-1/3 font-bold">Campaign</dt>
                                <dd className="sm:w-2/3 py-1">{campaign}</dd>
                            </>
                        )}
                        {priorRejectionReason && (
                            <>
                                <dt className="sm:w-1/3 font-bold text-red-700">
                                    Reason for rejection
                                </dt>
                                <dd className="sm:w-2/3 py-1 text-red-700">
                                    {priorRejectionReason}
                                </dd>
                            </>
                        )}
                        {rejectedBy && (
                            <>
                                <dt className="sm:w-1/3 font-bold text-red-700">Rejected by</dt>
                                <dd className="sm:w-2/3 py-1 text-red-700">{rejectedBy}</dd>
                            </>
                        )}
                        {rejectedAt && (
                            <>
                                <dt className="sm:w-1/3 font-bold text-red-700">Rejected at</dt>
                                <dd className="sm:w-2/3 py-1 text-red-700">
                                    {prettyDateTime(rejectedAt)}
                                </dd>
                            </>
                        )}
                    </dl>
                    {prescreenResult === 'PENDING' && (
                        <div
                            className={`p-3 border-t border-gray-300 flex flex-wrap ${
                                confirmApprove || approved ? 'bg-green-100' : ''
                            } ${confirmReject || rejected ? 'bg-red-100' : ''}`}
                        >
                            {!confirmApprove && !confirmReject && !approved && !rejected && (
                                <>
                                    <button
                                        className="rounded py-2 px-4 bg-red-700 font-light text-white hover:bg-red-800 flex"
                                        onClick={() => setConfirmReject(true)}
                                    >
                                        <Ban className="mr-3" />
                                        Reject
                                    </button>
                                    <button
                                        className="rounded py-2 px-4 ml-2 bg-green-700 font-light text-white hover:bg-green-800 flex"
                                        onClick={() => setConfirmApprove(true)}
                                    >
                                        <CheckCircle className="mr-3" />
                                        Approve
                                    </button>
                                </>
                            )}
                            {confirmApprove && (
                                <>
                                    <button
                                        className="rounded py-2 px-4 bg-gray-300 font-light text-gray-700 hover:bg-gray-400"
                                        onClick={() => setConfirmApprove(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="rounded py-2 px-4 ml-2 bg-green-700 font-light text-white hover:bg-green-800 flex items-center"
                                        onClick={() => updatePrescreenStatus('APPROVED')}
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? (
                                            <Loader />
                                        ) : (
                                            <CheckCircle className="mr-3" />
                                        )}
                                        <span>Confirm approval</span>
                                    </button>
                                </>
                            )}
                            {confirmReject && (
                                <>
                                    <textarea
                                        className="w-full block mb-3 border border-red-700 py-2 px-3 rounded"
                                        rows="5"
                                        placeholder="Enter reason for rejection..."
                                        value={rejectionReason}
                                        onChange={(event) => setRejectionReason(event.target.value)}
                                    ></textarea>
                                    {confirmRejectValidationError && (
                                        <p className="text-red-700 text-sm mb-3 w-full">
                                            Please enter a reason for the rejection.
                                        </p>
                                    )}
                                    <button
                                        className="rounded py-2 px-4 bg-gray-300 font-light text-gray-700 hover:bg-gray-400"
                                        onClick={() => setConfirmReject(false)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        className="rounded py-2 px-4 ml-2 bg-red-700 font-light text-white hover:bg-red-800 flex items-center"
                                        onClick={() => updatePrescreenStatus('REJECTED')}
                                        disabled={isSubmitting}
                                    >
                                        {isSubmitting ? <Loader /> : <Ban className="mr-3" />}
                                        <span>Confirm rejection</span>
                                    </button>
                                </>
                            )}
                            {approved && (
                                <span className="text-green-700">User has been approved.</span>
                            )}
                            {rejected && (
                                <span className="text-red-700">User has been rejected.</span>
                            )}
                        </div>
                    )}
                </>
            )}
        </section>
    );
};

export default UserPrescreen;
